"use client";

import "mapbox-gl/dist/mapbox-gl.css";
import React, { Fragment, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { useGetAuth, useGetCurrentUser } from "@/api/user";
import { ModeToggle } from "@/components/ui/theme-toggle";
import { usePathname, useRouter } from "next/navigation";
import { cookieExists } from "@/api/auth";
import { Button } from "@/components/ui/button";
import { useImpersonate } from "@/api/admin";
import TeamPicker from "@/components/team-picker";
import UserAvatar from "@/components/user-avatar";
import { useListTeams } from "@/api/teams";
import { TeamRole } from "@/gql/gen";
import { Slash } from "lucide-react";

export default function DashboardLayout({
  children,
  modal,
}: {
  children: React.ReactNode;
  modal: React.ReactNode;
}) {
  const router = useRouter();
  const token = cookieExists();
  useEffect(() => {
    if (!token) {
      router.push("/login");
    }
  }, [router, token]);

  const { data: user } = useGetCurrentUser();
  const { data: auth } = useGetAuth();
  const impersonateM = useImpersonate();

  return (
    <div className="flex h-screen flex-col font-sans">
      {auth?.impersonating ? (
        <div className="flex w-full items-center justify-between bg-brand px-4 py-2 text-background">
          <div></div>
          <div>
            Logged in as
            <span className="ml-2 font-bold">
              {user?.name} ({user?.email})
            </span>
          </div>
          <Button
            className=""
            onClick={() => {
              impersonateM.mutateAsync({});
            }}
            variant={"ghost"}
          >
            Logout
          </Button>
        </div>
      ) : null}
      <div className="flex items-center justify-between px-6 pt-3">
        <div className="flex h-9 items-center gap-x-4">
          <h3 className="font-stride text-2xl font-bold">STRIDE</h3>
          <TeamPicker />
        </div>
        <div className="flex items-center gap-2">
          <Teams />
          <ModeToggle />
          <User />
        </div>
      </div>

      {modal}
      <div className="mt-1 flex justify-between border-b border-border px-4 pb-1 text-sm">
        <Nav
          items={[
            {
              path: "/overview",
              name: "Overview",
            },
            {
              path: "/activities",
              name: "Activities",
            },
            {
              path: "/plans",
              name: "Plans",
            },
            {
              path: "/workouts",
              name: "Workouts",
            },
          ]}
        />
        <Nav
          items={[
            {
              path: "/help",
              name: "Help",
              onClick: () => {
                // @ts-expect-error "Plain" is not defined
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                Plain.open();
              },
            },
            {
              path: "/account",
              name: "Account",
            },
          ]}
        />
      </div>
      <div className="grow overflow-y-scroll">{children}</div>
    </div>
  );
}

const Teams = () => {
  const { data: teams } = useListTeams(TeamRole.Member);
  if (teams?.length === 0) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-2">
      {teams?.map((t) => (
        <Fragment key={t.id}>
          <div className="text-muted-foreground" key={t.id}>
            {t.name}
          </div>
          <Slash className="-rotate-12 stroke-border" width={18} />
        </Fragment>
      ))}
    </div>
  );
};

const User = () => {
  const user = useGetCurrentUser();
  return user.data ? <UserAvatar user={user.data} /> : null;
};

const Nav = ({
  items,
}: {
  items: { path?: string; name: string; onClick?: () => void }[];
}) => {
  const [hoveredPath, setHoveredPath] = useState<string | undefined>();
  return (
    <ul className="flex">
      {items.map((ni) => (
        <NavItem
          key={ni.path}
          title={ni.name}
          href={ni.path}
          onMouseOver={() => {
            setHoveredPath(ni.path);
          }}
          onMouseLeave={() => {
            setHoveredPath(undefined);
          }}
          onClick={ni.onClick}
        >
          {ni.path === hoveredPath && (
            <motion.div
              className="absolute bottom-0 left-0 -z-10 h-full rounded-md bg-muted"
              layoutId="navbar"
              aria-hidden="true"
              style={{
                width: "100%",
              }}
              transition={{
                type: "tween",
                duration: 0.2,
              }}
            />
          )}
        </NavItem>
      ))}
    </ul>
  );
};

const NavItem = (
  props: { title: string; href?: string } & React.HTMLAttributes<HTMLElement>,
) => {
  const path = usePathname();

  const router = useRouter();
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onClick) {
      props.onClick(e);
    } else {
      router.push(props.href ?? "");
    }
  };

  return (
    <li
      className={cn(
        "relative cursor-pointer px-3 py-2 text-muted-foreground/60 hover:text-foreground",
        {
          "dark:border-white text-foreground before:absolute before:-bottom-1 before:left-3 before:right-3 before:border-b-2 before:border-secondary-foreground":
            path === props.href,
        },
      )}
      {...props}
      onClick={handleClick}
    >
      {props.title}
      {props.children}
    </li>
  );
};
