"use client";

import * as React from "react";
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useListTeams, useSwitchUser } from "@/api/teams";
import {
  DisplayableTeamFragment,
  DisplayableUserFragment,
  TeamRole,
} from "@/gql/gen";
import { useCallback, useMemo, useState } from "react";
import { useGetAuth } from "@/api/user";
import { toast } from "sonner";
import { useLocalStorage } from "usehooks-ts";
import UserAvatar from "./user-avatar";
import { useRouter } from "next/navigation";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

export default function TeamPicker({ className }: PopoverTriggerProps) {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const [currentTeam, setCurrentTeam] = useLocalStorage<string | undefined>(
    "stride-current-team",
    undefined,
  );

  const { data: teams, isLoading } = useListTeams(TeamRole.Admin);
  const { data: authInfo } = useGetAuth();
  const switchUserM = useSwitchUser();

  const currentUserId = authInfo?.viewingUserId ?? authInfo?.userId;

  const selectedUser = useMemo(() => {
    const team = teams?.find((t) => t.id === currentTeam);
    if (team) {
      return team.members.find((m) => m.user.id == currentUserId)?.user;
    }
  }, [currentTeam, currentUserId, teams]);

  const switchUser = useCallback(
    (args: {
      team: DisplayableTeamFragment;
      user: DisplayableUserFragment;
    }) => {
      setCurrentTeam(args.team.id);
      toast.promise(
        switchUserM.mutateAsync({ teamID: args.team.id, userID: args.user.id }),
        {
          success: `Switched to ${args.user.name} in ${args.team.name}`,
          error: "Error switching user..",
        },
      );
      setOpen(false);
    },
    [setCurrentTeam, switchUserM],
  );

  if (isLoading || teams?.length === 0) {
    return null;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-label="Select a team"
          className={cn("min-w-[200px] justify-between px-2", className)}
        >
          {selectedUser ? (
            <>
              <UserAvatar user={selectedUser} />
              <span className="pl-2">
                {selectedUser.name}
                {selectedUser.id === authInfo?.userId ? " (You)" : null}
              </span>
            </>
          ) : (
            <p className="text-muted-foreground">Select a team..</p>
          )}
          <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search team..." />
          <CommandList className="max-h-[500px]">
            <CommandEmpty>No teams found.</CommandEmpty>

            <CommandGroup key={"overviews"} heading={"Dashboards"}>
              {teams?.map((team) => (
                <CommandItem
                  key={team.id}
                  value={team.id}
                  onSelect={() => {
                    setOpen(false);
                    router.push(`/overview/${team.slug}`);
                  }}
                  className="text-sm"
                >
                  {team.name} Overview
                </CommandItem>
              ))}
            </CommandGroup>
            {teams?.map((team) => (
              <CommandGroup key={team.id} heading={team.name}>
                {team.members.map((member) => (
                  <CommandItem
                    key={team.id + member.user.id}
                    value={team.id + member.user.id}
                    onSelect={() => {
                      switchUser({
                        team,
                        user: member.user,
                      });
                    }}
                    className="text-sm"
                  >
                    <UserAvatar user={member.user} />
                    <span className="pl-2">
                      {member.user.name}
                      {member.user.id === authInfo?.userId ? " (You)" : null}
                    </span>
                    <CheckIcon
                      className={cn(
                        "ml-auto h-4 w-4",
                        selectedUser?.id === member.user.id &&
                          currentTeam === team.id
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </CommandList>
          <CommandSeparator />
          <CommandList>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  setOpen(false);
                  router.push("/account/teams/new");
                }}
              >
                <PlusCircledIcon className="mr-2 h-5 w-5" />
                Create Team
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
