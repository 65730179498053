import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getToken, setCookieToken } from "./auth";
import { client } from "./constants";

export const useAdminListUsers = () => {
  return useQuery({
    queryKey: ["admin", "listUsers"],
    queryFn: async () => {
      const token = await getToken();
      return client.adminListUsers(undefined, {
        authorization: token.token,
      });
    },
    select: (u) => {
      return u.admin.listUsers;
    },
  });
};

export const useImpersonate = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (req: { id?: string }) => {
      const token = await getToken();
      return client.impersonate(req, {
        authorization: token.token,
      });
    },
    onSuccess: (s) => {
      setCookieToken(
        s.admin.impersonate.accessToken,
        s.admin.impersonate.refreshToken,
      );
      qc.removeQueries({ type: "inactive" });
      qc.refetchQueries({ type: "active" });
    },
  });
};

export const useReprocessAllActivities = () => {
  return useMutation({
    mutationFn: async (req: { id: string }) => {
      const token = await getToken();
      return client.reprocessActivities(req, {
        authorization: token.token,
      });
    },
  });
};
