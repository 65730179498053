import { DisplayableUserFragment } from "@/gql/gen";
import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { cn } from "@/lib/utils";

export default function UserAvatar({
  user,
  url,
  size,
}: {
  user: DisplayableUserFragment;
  url?: string;
  size?: "XL" | "LG" | "M";
}) {
  const name = (user?: DisplayableUserFragment) => {
    const n = user?.name ?? "";
    const sn = n.split(" ");
    if (sn.length > 1) {
      return [sn[0][0], sn[1][0]].map((s) => s.toUpperCase()).join("");
    } else if (sn.length === 1) {
      return sn[0]?.[0]?.toUpperCase();
    } else {
      return n;
    }
  };

  let w = 28;
  switch (size) {
    case "M":
      w = 48;
      break;
    case "LG":
      w = 96;
      break;
    case "XL":
      w = 144;
  }

  return (
    <Avatar
      className={cn("size-7 text-xs", {
        "size-12 text-2xl": size === "M",
        "size-24 text-2xl": size === "LG",
        "size-36 text-3xl": size === "XL",
      })}
    >
      {(url ?? user.avatarURL) && (
        <AvatarImage
          src={url ?? user.avatarURL ?? undefined}
          width={w * window.devicePixelRatio}
        />
      )}
      <AvatarFallback>{name(user)}</AvatarFallback>
    </Avatar>
  );
}
