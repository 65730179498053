import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./constants";
import { getToken, setCookieToken } from "./auth";
import { TeamRole } from "@/gql/gen";
import { formatISO } from "date-fns";

export const useListTeams = (role: TeamRole) => {
  return useQuery({
    queryKey: ["teams", "list", role],
    queryFn: async () => {
      const token = await getToken();
      return client.listTeams({ role }, { authorization: token.token });
    },
    select: (d) => d.teams.list,
  });
};

export const useGetTeam = (id: string) => {
  return useQuery({
    queryKey: ["teams", id],
    queryFn: async () => {
      const token = await getToken();
      return client.getTeam({ id }, { authorization: token.token });
    },
    select: (d) => d.teams.get,
  });
};

export const useGetTeamDashboard = (req: {
  teamID: string;
  start: Date;
  end: Date;
}) => {
  return useQuery({
    queryKey: ["teams", "dashboard", req],
    queryFn: async () => {
      const token = await getToken();
      return client.teamDashboard(
        {
          teamID: req.teamID,
          start: formatISO(req.start),
          end: formatISO(req.end),
        },
        { authorization: token.token },
      );
    },
    select: (d) => d.teams.dashboard,
  });
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (name: string) => {
      const token = await getToken();
      return client.createTeam(
        { name },
        {
          authorization: token.token,
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["teams"],
      });
    },
  });
};

export const useAcceptInvite = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (inviteCode: string) => {
      const token = await getToken();
      return client.acceptInvite(
        { inviteCode },
        {
          authorization: token.token,
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["teams"],
      });
    },
  });
};

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (input: {
      userID: string;
      teamID: string;
      role: TeamRole;
    }) => {
      const token = await getToken();
      return client.updateRole(input, {
        authorization: token.token,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["teams"],
      });
    },
  });
};

export const useSwitchUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (input: { userID: string; teamID: string }) => {
      const token = await getToken();
      return client.switchUser(input, {
        authorization: token.token,
      });
    },
    onSuccess: (s) => {
      setCookieToken(
        s.teams.switchUser.accessToken,
        s.teams.switchUser.refreshToken,
      );

      queryClient.removeQueries({ type: "inactive" });
      queryClient.refetchQueries({ type: "active" });
    },
  });
};
